import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import i18n from '../../i18n';

const languages = Object.keys(i18n);

const List = styled.ul`
    list-style-type: none;
    padding: 0;
    display: inline-flex;
`;

const Item = styled.li`
    text-align: center;
    display: block;
    margin: 0 10px;
`;

export const LanguagePicker = () => (
    <div style={{ display: 'inline' }}>
        <nav style={{ display: 'inline' }}>
            <List>
                {languages.map((lang, i) => (
                    <Item key={i}>
                        <Link to={`/${lang}`}>{lang}</Link>
                    </Item>
                ))}
            </List>
        </nav>
    </div>
);
