import React from 'react';
import styled from 'styled-components';
import './layout.css';

const Placeholder = styled.div`
    margin-top: 0;
    @media screen and (min-width: 768px) {
        margin-top: 125px;
    }
`;

const Container = styled.div`
    padding-top: 0.5em;
    @media screen and (min-width: 768px) {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;

        background: white;
        margin-top: 0;
    }
`;

const StyledLink = styled.a`
    text-transform: capitalize;
    text-decoration: none;
    height: inherit;
    padding: 10px;
    display: block;

    @media screen and (min-width: 768px) {
        flex: 1 1 0;
    }
`;

const Paragraph = styled.p`
    @media screen and (min-width: 768px) {
        flex: 1 1 0;
    }
`;

const CenteredDiv = styled.div`
    text-align: center;

    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: start;
    }
`;

export const Footer = ({ content }) => {
    const {
        language,
        footer: { tac, privacy },
    } = content;

    return (
        <div>
            <Placeholder />
            <Container>
                <CenteredDiv>
                    <Paragraph>Copyright 2021</Paragraph>
                    <StyledLink
                        href={`/${language}/legal/general-terms-and-conditions/`}
                        target="_blank"
                    >
                        {tac}
                    </StyledLink>

                    <StyledLink
                        href={`/${language}/legal/privacy-statement/`}
                        target="_blank"
                    >
                        {privacy}
                    </StyledLink>
                </CenteredDiv>
            </Container>
        </div>
    );
};
