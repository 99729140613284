import React from 'react';
import styled from 'styled-components';
import { Header } from './header';
import { Footer } from './footer';
import { Menu } from './menu';
import './layout.css';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
    max-width: 1500px;
    margin: auto;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
`;

const Background = styled.div`
    background: linear-gradient(white 20%, rgba(255, 255, 255, 0));
    // opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: white;
    z-index: -1;
    width: 100%;
    height: 100%;
`;

export const Layout = ({ children, content }) => (
    <Container>
        <Header content={content} />
        {children}
        <Footer content={content} />
        <Background />
    </Container>
);
