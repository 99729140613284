import React from 'react';
import i18n from '../../i18n';
import { Layout } from '../containers/layout';
import { DynamicComponent } from '../components/dynamic-component';

export default ({ language }) => {
    const content = i18n[language || 'en'];

    return (
        <Layout content={content}>
            {content.pages.info
                ? content.pages.info.map(o => <DynamicComponent options={o} />)
                : ''}
        </Layout>
    );
};
