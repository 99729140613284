import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Menu } from './menu';

const Style = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 880px) {
        flex-direction: row;
    }
`;

const StyledLogo = styled.div`
    padding-left: 1em;
    padding-right: 1em;
`;

const StyledSub = styled.p`
    text-transform: uppercase;
    margin-top: 0;
    /* text-align: center; */
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    &,
    &:visited,
    &:hover,
    &:active {
        color: #000;
    }
`;

const StyledTitle = styled.h1`
    text-transform: capitalize;
    margin-top: 3px;
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
        font-size: 25px;
    }
`;

export const Header = ({ content }) => {
    const {
        language,
        title: { sub, name, link },
    } = content;
    return (
        <Style>
            <StyledLogo>
                <StyledLink to={`/${language}${link}`}>
                    <StyledTitle>{name}</StyledTitle>
                    <StyledSub>{sub}</StyledSub>
                </StyledLink>
            </StyledLogo>

            <Menu content={content} />
        </Style>
    );
};
