import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { LanguagePicker } from '../components/language-picker';

const Style = styled.div`
    max-width: 100%;
    flex-grow: 1;
`;

const Nav = styled.nav`
    // background: #82d9a6;
    // display: inline;
`;

const List = styled.ul`
    // display: inline;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    /* justify-content: start; */

    @media screen and (max-width: 500px) {
        display: none;
        flex-direction: column;
        padding-top: 4em;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;

        &.toggled {
            display: flex;
        }
    }
`;

const Item = styled.li`
    text-align: center;
    display: block;

    @media screen and (min-width: 500px) {
        flex: 1 1 0;
    }
    @media screen and (max-width: 500px) {
        display: none;
        border-bottom: 1px solid #000;
        &:first-child {
            border-top: 1px solid #000;
        }
        &.toggled {
            display: block;
        }
    }
`;

const StyledLink = styled(Link)`
    text-transform: capitalize;
    &,
    &:visited,
    &:hover,
    &:active {
        color: #000;
    }
    &:hover {
        background-color: #eee;
    }
    background-color: #fff;
    text-decoration: none;
    height: inherit;
    padding-top: 1em;
    padding-bottom: 1em;
    display: block;
`;

const Hamburger = styled.div`
    position: fixed;
    cursor: pointer;
    top: 1em;
    right: 1em;

    div {
        width: 35px;
        height: 5px;
        background-color: #03071e;
        margin: 6px 0;
        display: block;
        transition: 0.4s;
    }

    @media screen and (min-width: 500px) {
        display: none;
    }
`;

const Bar1 = styled.div`
    /* Rotate first bar */
    &.toggled {
        -webkit-transform: rotate(-45deg) translate(-9px, 6px);
        transform: rotate(-45deg) translate(-9px, 6px);
    }
`;
const Bar2 = styled.div`
    /* Fade out the second bar */
    &.toggled {
        opacity: 0;
    }
`;
const Bar3 = styled.div`
    /* Rotate last bar */
    &.toggled {
        -webkit-transform: rotate(45deg) translate(-8px, -8px);
        transform: rotate(45deg) translate(-8px, -8px);
    }
`;

export const Menu = ({ content: { language, menu } }) => {
    const [isToggled, setToggled] = useState(false);

    return (
        <Style>
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
            ></link>
            <Nav>
                <List className={isToggled ? 'toggled' : null}>
                    {menu.map(({ name, link }) => (
                        <Item
                            className={isToggled ? 'toggled' : null}
                            onClick={() => setToggled(false)}
                        >
                            <StyledLink to={`/${language}${link}`}>
                                {name}
                            </StyledLink>
                        </Item>
                    ))}
                </List>
            </Nav>

            <Hamburger onClick={() => setToggled(!isToggled)}>
                <Bar1 className={isToggled ? 'toggled' : null} />
                <Bar2 className={isToggled ? 'toggled' : null} />
                <Bar3 className={isToggled ? 'toggled' : null} />
            </Hamburger>
            <LanguagePicker />
        </Style>
    );
};
